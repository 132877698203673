<template>
    <div>
        <b-row>
            <b-col cols="12">
                <h2>Devices Missing GPS Coordinates</h2>
            </b-col>
            <b-col cols="12">
                <!-- Toolbar Here -->
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <!-- Table Goes Here -->
                <b-button-toolbar key-nav aria-label="Selection Options">
                    <b-dropdown class="mx-1" right text="Select">
                        <b-dropdown-item @click="selectAll">All Rows</b-dropdown-item>
                        <b-dropdown-item @click="deselectAll">Unselect All Rows</b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown v-if="selected.length > 0" class="mx-1" right text="Batch Processing">
                        <b-dropdown-item @click="geocodeSelectedWithGoogle">Geocode Slected With Google</b-dropdown-item>
                    </b-dropdown>
                </b-button-toolbar>
               
                <b-progress v-if="showProgress" :max="progressMax" height="2rem">
                    <b-progress-bar :value="progressCurrent">
                        <span>{{progressTitle}} <strong>{{ progressCurrent }} / {{ progressMax }}</strong></span>
                    </b-progress-bar>
                </b-progress>
                <b-table
                    ref="DeviceGPSBulkUpdate"
                    striped
                    :small="true"
                    :items="tableValues"
                    :fields="fields"
                    selectable
                    select-mode="multi"
                    selected-variant="primary"
                    @row-selected="onRowSelected"
                    style="white-space: pre-line;"
                >
                <template v-slot:cell(device_id)="data">
                    <span><b>Device:</b> {{data.item.device_id}}</span><br>
                    <span><b-link v-b-popover.hover.left="'View This Device (Opens In New Tab)'" target="_blank" :to="getDeviceLink(data.item.device_id)">View This Device (Opens In New Tab)</b-link></span>
                </template>
                
                </b-table>
                
                
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-button-toolbar justify>
                    <b-button variant="info" @click="$router.go(-1)">Exit</b-button>
                </b-button-toolbar>
            </b-col>
        </b-row>
    </div>
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');
const _ = require('underscore');
var fileDownload = require('js-file-download');

export default {
    name: 'bulkgeocodemissing',
    components: {
    },
    props:{
    },
    data(){
        return{
            showProgress: false,
            progressTitle: '',
            progressCurrent: 0,
            progressMax: 0,
            selected: [],
            tableValues: [],
            fields: [
                { key: 'device_id', label: "Device ID", sortable: true },
                { key: 'site_address', label: "Site Address", sortable: true },
                { key: 'device_latitude', label: "Latitude", sortable: true },
                { key: 'device_longitude', label: "Longitude", sortable: true },
                { key: 'gps_accuracy', label: "Accuracy", sortable: true },
                { key: 'result', label: "Result", sortable: false },
                
            ],
        }
    },
    methods: {
        setProgress(title, cur, total){
            this.progressTitle = title;
            this.progressMax = total;
            this.progressCurrent = cur;
        },
        geocodeSelectedWithGoogle(){
            this.showProgress = true;
            var items = this.selected;
            this.geocodeNextWithGoogle(items, 0);
        },
        geocodeNextWithGoogle(selection, index){
            this.setProgress("Geocoding Records", index + 1, selection.length);
            var itm = selection[index];
            this.performGoogleGeocode(itm,()=>{
               index++;
                if(selection.length - 1 >= index){
                    this.geocodeNextWithGoogle(selection, index);
                }else{
                    this.showProgress = false;
                }
            });
        },
        performGoogleGeocode(itm, geoCodeCallback){
            const geocoderInstance = axios.create({
                timeout: 10000,
                headers: {
                    'Content-Type': 'application/json'
                    },
                crossdomain: true,
                withCredentials: true,
            });
            var addessObj = {
                address: itm.site_address
            };
            //Get User Token and Fetch The Values Required For This Page
            geocoderInstance.post(process.env.VUE_APP_API_BASE_URL + '/atils/googlegeocode.json', addessObj)
            .then(async (response) => {
                console.log(response);
                var gpsResp = response.data.result[0].geolocation;
                if(gpsResp.accuracyType == "ROOFTOP" || gpsResp.accuracyType ==  "RANGE_INTERPOLATED"){
                    // Good Enough
                    var acc = 200;
                    if(gpsResp.accuracyType == "ROOFTOP"){
                        acc = 50;
                    }
                    // Perform Update on device_id
                    var deviceUpdateData = {
                        device_id: itm.device_id,
                        device_latitude: gpsResp.latitude,
                        device_longitude: gpsResp.longitude,
                        device_gps_accuracy: acc
                    };
                    instance.post(process.env.VUE_APP_API_BASE_URL + "/devices/" + itm.device_id + ".json", deviceUpdateData)
                    .then((response)=>{
                        itm.result = `200 - ${gpsResp.accuracyType}`;
                        itm.device_latitude = gpsResp.latitude;
                        itm.device_longitude = gpsResp.longitude;
                        itm.gps_accuracy = acc;
                        if(geoCodeCallback != null){
                            geoCodeCallback();
                        }
                    })
                    .catch((err)=>{
                        // TODO: An Error Has Occured Do Something Useful With it. Resp: "No" - TR 2020-09-28
                        console.log(`Failed Durring Device Data Update`)
                        console.log(err)
                        itm.result = `Error in Device Update`;
                        this.makeToast("Failed To Update Device Data", "An error has occured durring update of this device record.", 'danger', 30)
                        if(geoCodeCallback != null){
                            geoCodeCallback();
                        }
                    })
                }else{
                    itm.result = `JUNK - ${gpsResp.accuracyType}`;
                    // Not Good Enough
                    if(geoCodeCallback != null){
                        geoCodeCallback();
                    }
                }
                
            })
            .catch((error) => {
                console.log(error)
                this.makeToast(`Google Geocode Failed`, `Failed To Geocode For Address ${itm.site_address}`, 'danger', 30)
                itm.result = `Error in Google Geocode`;
                if(error.response.status == 404){
                    console.log("Google Geocode Failed");
                }
                if(geoCodeCallback != null){
                    geoCodeCallback();
                }
            });
        },
        getDeviceLink(deviceID){
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: 'devices'});
            if(defaultFormForType == null){
                return '.'
            }else if(defaultFormForType.length == 0){
                return '.'
            }else{
                return '/home/form/' + defaultFormForType[0].id + '/' + deviceID
            }
        },
        onRowSelected(items) {
            this.selected = items
        },
        selectAll(){
            this.$refs.DeviceGPSBulkUpdate.selectAllRows();
        },
        deselectAll(){
            this.$refs.DeviceGPSBulkUpdate.clearSelected();
        },
        goToDevice(){
            var thisFormType = 'devices';
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: thisFormType});
            if(defaultFormForType == null){
                this.makeToast("No Form Available To Handle This Request", `Setup a form for ${thisFormType}. \nIf you think this is an error, logout and log back in.`)
            }else if(defaultFormForType.length == 0){
                this.makeToast("No Form Available To Handle This Request", `Setup a form for ${thisFormType}. \nIf you think this is an error, logout and log back in.`)
            }else{
                this.$router.push({path: '/home/form/' + defaultFormForType[0].id + '/' + this.deviceID })
            }
        },
        makeToast(toastTitle, toastBody = '', toastVariant = null, timer = 20){
            this.$bvToast.toast(toastBody, {
                variant: toastVariant,
                toaster: 'b-toaster-bottom-center',
                title: toastTitle,
                autoHideDelay: timer * 1000,
                appendToast: true
            })
        },
        fetchTableData(){
            instance.get(process.env.VUE_APP_API_BASE_URL + "/atils/devices_missing_gps.json")
            .then((response)=>{
                this.tableValues = response.data.result.records;
            })
            .catch((err)=>{
                // TODO: An Error Has Occured Do Something Useful With it. Resp: "No" - TR 2020-09-28
                console.log(`Failed Durring Table Data Fetch`)
                console.log(err)
                this.makeToast("Failed To Fetch Table Data", "An error has occured durring update of this device record.", 'danger', 30)
            })
        },
        initBeforeMount(){
            this.fetchTableData();
        }
    },
    watch:{

    },
    computed: {

    },
    mounted(){

    },
    beforeDestroy(){
        
    },
    beforeMount (){
        this.initBeforeMount();
    },
    beforeCreate(){
        //this.initBeforeCreate();
    },
}
</script>

<style scoped>
.array-spaced{
  margin-bottom: 0.5rem;
}
.fgroup-spacer{
    width: 100%;
    height: 1px;
    margin-bottom: 0.5rem;
}
.blue-bg{
    background-color: #ebf5ff;
    border-radius: 1em;
    border-width: 0.5em;
    padding: 0.5em;
}
.explain-fail{
    font-size: 0.75em;
}
.larger-header{
    font-size: 1.5em;
}
</style>